<template>
    <div style="height:100%">
        <a-spin :spinning="spinning" tip="正在加载，请稍等……">
            <div class="multi-ctrls">
                <a-tree-select
                    v-model="folderId"
                    style="width: 200px;float:left;"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="folders"
                    placeholder="请选择目录"
                    tree-default-expand-all
                    @change="changeFolder"
                >
                    <a-icon slot="suffixIcon" type="down" />
                    <template slot="title" slot-scope="item">
                        <div class="tree-name-wrap">
                            {{item.titleCopy}}
                            <div class="actions">
                                <a-icon type="edit" @click.stop="editCate(item)" />
                                <a-icon type="delete" @click.stop="delCate(item)" />
                            </div>
                        </div>
                    </template>
                </a-tree-select>
                <a-button type="link" @click="showModal"><span class="iconfont icon-xinjianwenjianjia"></span>新建目录</a-button>
                <a-button type="danger" :disabled="selectedRowKeys.length===0" @click="mutilDel">
                    删除
                </a-button>
            </div>
            <a-table 
                :columns="columns" 
                :data-source="list" 
                :rowKey="record=>record.collectionCode" 
                :pagination="false" 
                :bordered="false"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                <template slot="collectionName" slot-scope="text,record">
                    <a href="javascript:;" @click="visitThis(record)" v-if="record.collectionCode!==editKey">{{text}}</a>
                    <div class="cell-edit" v-else>
                        <a-input v-model="record.collectionName" style="width:150px" />
                        <a-icon type="check-circle" theme="filled" title="保存" @click="save(record)" />
                        <a-icon type="close-circle" theme="filled" title="取消" @click="editKey=null;getFiles()" />
                    </div>
                </template>
                <template slot="actions" slot-scope="text,record">
                    <a-button type="link" @click="edit(record)">重命名</a-button>
                    <a-button type="link" @click="move(record)" title="移动到其他目录">移动</a-button>
                    <a-button type="link" @click="del(record)">删除</a-button>
                </template>
            </a-table>

            <a-modal v-model="visible" title="新建目录" :footer="null" :centered="true">
                <div class="option">
                    <div class="h2">目录名称：</div>
                    <a-input v-model="folderName" />
                </div>
                <div class="option">
                    <div class="h2">上级目录：</div>
                    <a-tree-select
                        v-model="parentId"
                        style="width: 100%;"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="folders"
                        placeholder="请选择目录"
                        tree-default-expand-all
                    >
                    </a-tree-select>
                </div>
                <div class="btns">
                    <a-button type="primary" ghost @click="cancleCreateFolder">取消</a-button>
                    <a-button type="primary" @click="goCreateFolder">确定</a-button>
                </div>
            </a-modal>

            <a-modal v-model="visible2" title="选择目录" :footer="null" :centered="true">
                <div class="option">
                    <a-tree-select
                        v-model="targetId"
                        style="width: 100%;"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="folders"
                        placeholder="请选择目录"
                        tree-default-expand-all
                    >
                    </a-tree-select>
                </div>
                <div class="btns">
                    <a-button type="primary" ghost @click="cancelMove">取消</a-button>
                    <a-button type="primary" @click="goMove">确定</a-button>
                </div>
            </a-modal>
        </a-spin>
    </div>
</template>

<script>
import axios from 'axios';
import { messageTips, setSessionItem } from '../../until/tools';
const columns = [
	{
		title: '数据集',
		dataIndex: 'collectionName',
		key: 'collectionName',
		scopedSlots: { customRender: 'collectionName' },
	},
	{
		title: '数据库',
		dataIndex: 'cubeName',
		key: 'cubeName',
    },
    {
		title: '收藏时间',
		dataIndex: 'createTime',
        key: 'createTime',
        align:'center'
    },
    {
		title: '操作',
		dataIndex: 'actions',
		key: 'actions',
		scopedSlots: { customRender: 'actions' },
        align:'center'
	},
];
export default {
    name:'Collections',
    data() {
        return {
            columns,
            allList:[],
            list:[],
            editKey:null,
            selectedRowKeys:[],
            visible:false,
            visible2:false,
            folderName:'',
            folders:[],
            parentId:null,
            folderId:null,
            targetId:null,
            waitingMove:null,
            spinning:false
        }
    },

    watch:{
        folderId(value) {
            if(value) {
                this.getFolderFiles(value);
            } else {
                this.list = [...this.allList];
            }
        }
    },
    created() {        
        this.getFiles();
        this.getAllFolder();

        let folderId = this.$route.query.folderId;
        if(folderId) {
            this.folderId = parseInt(folderId);
        } else {
            return null;
        }
    },
    methods:{
        showModal() {
            this.visible = true;
            let folderId = this.$route.query.folderId;
            this.folderName = '';
            this.parentId = null;
            if(folderId) {
               this.parentId = folderId;
            } else {
                this.parentId = null;
            }
        },
        getAllFolder() {
            this.$http.user_collection_get_allFolder().then(res=>{
                if(res&&res.list) {
                    this.folders = this.setFolderTree(res.list,0,[]);
                    this.folders.unshift({key:null,value:null,title:'全部'});
                }
            });
        },
        goCreateFolder() {
            if(this.folderName==='') {
                messageTips('请填写目录名称',2);
                return false;
            }
            let params = {
                name:this.folderName,
            }
            if(this.parentId) {
                params.parentId = this.parentId;
            }
            this.$http.user_createFolder(params).then((res)=>{
                if(res) {
                    if(res.entity) {
                        this.getAllFolder();
                        this.visible = false;
                    } else {
                        messageTips(res.message,1);
                    }                    
                }
            });
        },
        cancleCreateFolder() {
            this.visible = false;
        },
        changeFolder(id) {
            this.folderId = id;
            this.$router.push({
                path:'/user_collections',
                query:{ folderId:id }
            });
        },
        getFiles() {
            this.$http.user_getFiles().then(res=>{
                if(res) {
                    if(res.all) {
                        this.allList = res.all;
                        if(this.folderId) {
                            this.getFolderFiles(this.folderId);
                        } else {
                            this.list = [...this.allList];
                        }
                    }
                }
            })
        },
        getFolderFiles(folderId) {            
            this.list = [];
            this.allList.forEach(item=>{
                if(item.parentCode===folderId) {
                    this.list.push(item);
                }
            })
        },
        visitThis(item) {
            this.spinning = true;
            var contents = JSON.parse(item.contents);
            if (contents.metaFixed.length > 0 && contents.metaFixed[0] !== '') {
                contents.dimensionVOLst.forEach((v)=> {
                    if (contents.metaFixed.indexOf(v.codeName) !== -1) {
                        v.codes = [v.codes[0]];
                    }
                });
            }
            var cubeId = contents.cubeId;
            setSessionItem('dims',JSON.stringify(contents));
            this.$router.push({
                path:'/datas_data',
                query:{cubeId:cubeId}
            });
        },
        edit(item) {
            this.editKey = item.collectionCode;
        },
        save(item) {
            this.$http.user_editFile({id:item.collectionCode,newName:item.collectionName}).then(res=>{
                if(res) {
                    if(res.success) {
                        this.editKey = null;
                    } else {
                        messageTips(res.message);
                    }
                }
            });
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        del(item) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    let params = {
                        parentId:item.parentCode,
                        ids:item.collectionCode
                    }
                    that.confirmDel(params);
                },
                onCancel() {},
            });  
        },
        mutilDel() {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText:'继续',
                cancelText:'取消',
                centered:true,
                onOk() {
                    var arr = [];
                    that.list.forEach(item=>{
                        if(that.selectedRowKeys.indexOf(item.collectionCode)!==-1) {
                            arr.push({ids:item.collectionCode,parentId:item.parentCode});
                        }
                    });
                    axios.all(arr.map(item=>that.$http.user_delFile(item))).then(axios.spread(()=>{
                        that.getFiles();
                    }));
                },
                onCancel() {},
            });  
        },
        confirmDel(params) {
            this.$http.user_delFile(params).then(res=>{
                if(res) {
                    if(res.success) {
                        this.getFiles();
                    } else {
                        messageTips(res.message,1);
                    }
                }
            });
        },
        setFolderTree(arr,pid,result) {
            arr.forEach((item)=>{
                item.value = item.collectionCode;
                item.key = item.collectionCode;
                item.title = item.collectionName;
				item.scopedSlots = { title: 'title' };
				item.titleCopy = item.collectionName;
                if(item.parentCode===pid) {
                    if(!item.children) {
                        item.children = [];
                    }
                    item.children = this.setFolderTree(arr,item.collectionCode,item.children);
                    result.push(item);
                }
            });
            return result;
        },
        move(item) {
            this.visible2 = true;
            this.waitingMove = item;
            this.targetId = null;
        },
        goMove() {
            if(!this.targetId) {
                messageTips('请选择目录',2);
                return false;
            }
            let params = {
                ids:this.waitingMove.collectionCode,
                parentId:this.waitingMove.parentCode,
                targetId:this.targetId
            };
            this.$http.user_collection_move_file(params).then(res=>{
                if(res) {
                    if(res.success) {
                        this.$router.push({
                            path:'/user_collections',
                            query:{folderId:this.targetId}
                        });
                        this.folderId = this.targetId;
                        this.visible2 = false;
                        this.getFiles();
                    } else {
                        messageTips(res.message,1);
                    }
                }
            })
        },
        cancelMove() {
            this.visible2 = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/_variables.scss';
    .cell-edit {
        .anticon {
            margin:0 0 0 10px;
            font-size: 20px;
        }
        .anticon-check-circle {
            color:$primary-color;
        }
        .anticon-close-circle {
            color:$warning-color;
        }
    }
    .multi-ctrls {
        padding:20px 0;
        text-align: right;
    }

    .ant-modal-body {
        .btns {
            text-align: center;
            padding:40px 0 20px 0;
            button {
                margin:0 20px;
            }
            .ant-btn-primary {
                background-color: $primary-color;
                border-color: $primary-color;
            }
            .ant-btn-background-ghost.ant-btn-primary {
                color:$primary-color;
                border-color: $primary-color;
            }
        }

        .h2 {
            padding-bottom: 5px;
            font-weight: bold;
        }
        .option {
            margin-bottom: 20px;
        }
    }
</style>